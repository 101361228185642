<template>
  <div>

    <h1 class="text-2xl font-bold mb-5">{{ company.name }} Admins</h1>

    <div class="border border-solid border-blue-200 rounded overflow-hidden">
      
      <datatable 
        :ajax="true"
        :ajax-pagination="true"
        :url="`${this.$baseurl}/admins`"
        :columns="admins.columns" 
        :actions="admins.actions" 
        :query="admins.query" 
        :dropdown="false"
        :on-click="click"
        ref="table"
      />

    </div>

    <modal className="w-full md:w-3/5 xl:w-4/10" ref="modal">

      <div v-if="admin">

        <h1 class="text-2xl font-bold mb-10">Review Admin</h1>
        
        <div class="mb-10">

          <div class="grid grid-cols-1 md:grid-cols-2 border-b border-blue-200">
            <div class="col-span-1 py-4">
              <div class="opacity-75 text-sm font-medium mb-1">First Name</div>
              <div class="text-gray-500 text-sm flex flex-row items-center">
                <ion-icon name="person-outline" class="text-lg mr-2"></ion-icon>
                {{ admin.name }}
              </div>
            </div>
            <div class="col-span-1 py-4 pl-4 -ml-4 border-t md:border-t-0 md:border-l border-blue-200">
              <div class="opacity-75 text-sm font-medium mb-1">Last Name</div>
              <div class="text-gray-500 text-sm flex flex-row items-center">
                <ion-icon name="person-outline" class="text-lg mr-2"></ion-icon>
                 {{ admin.last_name }}
              </div>
            </div>
          </div>

          <div class="grid grid-cols-1 border-b border-blue-200">
            <div class="col-span-1 py-4">
              <div class="opacity-75 text-sm font-medium mb-1">Roles ({{ admin.roles.length }})</div>
              <div class="text-gray-500 text-sm flex flex-wrap flex-row items-center">
                <ion-icon name="person-outline" class="text-lg mr-2"></ion-icon>
                <div class="flex flex-wrap">
                  <template v-for="(role, n) in admin.roles">
                    <div
                    class="badge badge-sm mr-3 mb-3"
                    :class="{ [`badge-${role.slug.match(/^super/) ? 'orange' : 'blue'}-soft-outline`]: true }"
                    :key="n"
                    >
                      {{ role.name }}
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>

          <div class="grid grid-cols-1 border-b border-blue-200">
            <div class="col-span-1 py-4">
              <div class="opacity-75 text-sm font-medium mb-1">Permissions</div>
              <div class="text-gray-500 text-sm flex flex-wrap flex-row items-center">
                <ion-icon name="person-outline" class="text-lg mr-2"></ion-icon>
                {{ admin.permissions.length }} {{ 'permission' | toPlural(admin.permissions) }}
              </div>
            </div>
          </div>

        </div>

        <form @submit.prevent="update(admin)" class="mb-10" v-if="isSalesAgent">
          <div class="font-bold mb-5">Update Agent Code</div>

          <template v-if="getFormError(updateForm)">
            <div class="alert alert-red-soft mb-10">
              <span class="alert-icon">!</span>
              <span>{{ getFormError(updateForm) }}</span>
            </div>
          </template>

          <div class="flex flex-wrap md:flex-no-wrap">
            <form-group
              class="w-full mb-0"
              left-icon="briefcase-outline"
              name="agent_code"
              :form="updateForm"
              v-model="updateForm.data.agent_code.value"
            >
              Agent Code
            </form-group>
            <button
              type="submit"
              class="btn btn-blue ml-4"
              :disabled="updateForm.loading"
            >
              <span v-if="updateForm.loading">Updating</span>
              <span v-else>Update</span>
            </button>
          </div>
        </form>

        <div class="">
          <button type="button" class="btn btn-red-outline mr-3" @click="confirmDelete" :disabled="deleteForm.loading || updateForm.loading">
            <span v-if="deleteForm.loading">Declining</span>
            <span v-else>Delete</span>
          </button>
        </div>

      </div>

    </modal>

    <modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" ref="successModal">

      <img :src="assets.icons.lg.checkmark" class="mx-auto mb-8" height="90" alt="Check Mark">

      <div class="text-lg font-bold mb-4">
        Success!
      </div>
      <div class="text-xs mb-10">
        Admin updated successfully
      </div>

      <button type="button" class="btn btn-blue" @click.prevent="$refs.successModal.close">
        Okay
      </button>
      
    </modal>

    <modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" ref="deletedModal">

      <img :src="assets.icons.lg.checkmark" class="mx-auto mb-8" height="90" alt="Check Mark">

      <div class="text-lg font-bold mb-4">
        Success!
      </div>
      <div class="text-xs mb-10">
        Admin deleted successfully
      </div>

      <button type="button" class="btn btn-blue" @click.prevent="$refs.deletedModal.close">
        Okay
      </button>
      
    </modal>

    <modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" ref="deletedConfirmationModal">

      <img :src="assets.icons.lg.exclamation" class="mx-auto mb-8" height="90" alt="Check Mark">

      <div class="text-lg font-bold mb-4">
        Delete this admin?
      </div>

      <template v-if="getFormError(deleteForm)">
        <div class="alert alert-red-soft mb-10">
          <span class="alert-icon">!</span>
          <span>{{ getFormError(deleteForm) }}</span>
        </div>
      </template>

      <div class="text-center">
        <button type="button" class="btn btn-blue mr-3" @click.prevent="deleteAdmin(admin)" :disabled="deleteForm.loading || updateForm.loading">
          <span v-if="deleteForm.loading">Deleting</span>
          <span v-else>Okay</span>
        </button>
        
        <button type="button" class="btn btn-red" @click.prevent="$refs.deletedConfirmationModal.close">
          Cancel
        </button>
      </div>
      
    </modal>

  </div>
</template>
<script>
  export default {
    data() {
      return {
        admin: null,
        admins: this.$options.resource([], {
          actions: [
            // {
            //   text: 'delete',
            //   class: 'border border-red-500 text-red-500 rounded-sm px-4 py-2',
            //   action: this.confirmDelete,
            //   disabled: admin => admin.id === this.user.id
            // }
          ],
          columns: [
            {
              name: 'name',
              th: 'Name',
              render: admin => `${admin.name} ${admin.last_name}`
            },
            {
              name: 'roles',
              th: 'Role',
              render: (users, roles) => roles[0].name
            },
            {
              name: 'agent_code',
              th: 'Agent Code',
            },
            {
              name: 'email',
              th: 'Email',
            },
            {
              name: 'phone_no',
              th: 'Phone',
            },
          ],
          loading: false,
          query: '',
        }),
        deleteForm: this.$options.basicForm([]),
        updateForm: this.$options.basicForm([
          'agent_code'
        ]),
      }
    },
    computed: {
      company() {
        return this.user.company;
      },
      isSalesAgent() {
        return this.admin?.roles.find(role => role.slug == 'super_sales_agent');
      }
    },
    methods: {
      click(admin) {
        this.admin = admin;
        this.updateForm.data.agent_code.value = admin.agent_code || '';
        this.$refs.modal.open();
      },
      confirmDelete() {
        this.$refs.modal.close();
        this.$refs.deletedConfirmationModal.open();
      },
      async deleteAdmin(admin) {
        this.deleteForm.loading = true;
        await this.sendRequest('admin.admins.delete', admin.id, {
          success: () => {
            this.$refs.deletedModal?.open();
            this.getAdmins();
          },
          error: error => {
            this.deleteForm.error = error;
          }
        });
        this.deleteForm.loading = false;
      },
      async update(admin) {
        this.updateForm.loading = true;
        await this.sendRequest('admin.admins.update', admin.id, {
          data: this.getFormData(this.updateForm),
          success: () => {
            this.$refs.modal.close();
            this.$refs.successModal.open();
            this.getAdmins();
          },
          error: error => {
            this.updateForm.error = error;
          }
        });
        this.updateForm.loading = false;
      },
      async getAdmins() {
        this.$refs.table.loadAjaxData();
      }
    }
  }
</script>