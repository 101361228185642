<template>
	<div>
		<div class="flex">
			<div class="w-full sm:w-2/3 md:w-3/5 lg:w-4/5 xl:w-1/2 mx-auto">

				<h1 class="text-2xl font-bold mb-5">Add an Admin</h1>

				<form @submit.prevent="submit" class="card border border-blue-200 pt-16 pb-10 px-10 md:px-16" key="company">
					
					<template v-if="getFirstError(form)">
						<div class="alert alert-red-soft">
							<span class="alert-icon">!</span>
							<span>{{ getFirstError(form) }}</span>
						</div>
					</template>

					<form-group
						left-icon="business-outline"
						name="first_name"
						v-model="form.data.first_name.value"
						:form="form"
					>
						First Name
					</form-group>

					<form-group
						left-icon="business-outline"
						name="last_name"
						v-model="form.data.last_name.value"
						:form="form"
					>
						Last Name
					</form-group>

					<form-group
						left-icon="mail-outline"
						name="email"
						v-model="form.data.email.value"
						:form="form"
					>
						Email Address
					</form-group>

					<form-group
						left-icon="person-outline"
						name="phone"
						v-model="form.data.phone.value"
						:form="form"
					>
						Phone Number
					</form-group>

					<form-group
						type="select"
						:options="roleOptions"
						left-icon="person-outline"
						name="role"
						v-model="form.data.role.value"
						:form="form"
						:loading="roles.loading"
					>
						Role
					</form-group>

					<form-group
						left-icon="person-outline"
						name="agent_code"
						v-model="form.data.agent_code.value"
						:form="form"
						v-if="wantsSalesAgent"
					>
						Agent Code
					</form-group>

					<div class="text-right">
						<button type="submit" class="button bg-blue-500 hover:bg-blue-600 text-white" :disabled="form.loading || roles.loading">
							<span v-if="form.loading">Submitting...</span>
							<span v-else>Next</span>
						</button>
					</div>

				</form>

			</div>
		</div>

		<modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" ref="successModal">

			<img :src="assets.icons.lg.checkmark" class="mx-auto mb-8" height="90" alt="Check Mark">

			<div class="text-lg font-bold mb-4">
				Success!
			</div>
			<div class="text-xs mb-10">
				Admin registered successfully
			</div>

			<router-link :to="{name: 'dashboard'}" class="button bg-blue-500 hover:bg-blue-600 text-white">
				Go to Dashboard
			</router-link>
			
		</modal>

		<modal className="w-full md:w-2/5 xl:w-3/10 text-center" ref="helpModal" @close="closeModal(modalKey, $refs.helpModal)">

			<div class="mb-10">
				<div class="text-xl font-bold mb-10">
					Adding an admin.
				</div>

				<div class="font-light text-gray-500 text-sm">
					<p class="mb-2">Here you can create another user with limited or complete administrative priviledges.</p>
					<!-- <p class="mb-2">Fill the form below, submit and they will be notified to log in to the system.</p> -->
				</div>
			</div>

			<button
				type="button"
				class="button border border-blue-500 hover:bg-blue-500 text-blue-500 hover:text-white"
				@click.prevent="$refs.helpModal.close"
			>
				Got it.
			</button>

		</modal>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				company: null,
				modalKey: 'admins-new-super-help',
				form: this.$options.basicForm([
					{ name: 'first_name', rules: 'required|alpha'},
					{ name: 'last_name', rules: 'required|alpha'},
					'email',
					'phone',
					{ name: 'agent_code', rules: 'nullable' },
					'role',
				])
			}
		},
		computed: {
			roles() {
				return this.resources.roles;
			},
			roleOptions() {
				return this.roles?.data?.filter(role => role?.slug?.match(/^super/))?.map(role => ({
					title: role.name,
					value: role.slug
				})) || [];
			},
			wantsSalesAgent() {
				return this.form.data.role.value === 'super_sales_agent';
			}
		},
		mounted() {
			this.openModal(this.modalKey, this.$refs.helpModal);
		},
		watch: {
			wantsSalesAgent(value) {
				if (!value) {
					this.form.data.agent_code.value = '';
					this.form.data.agent_code.rules = 'nullable';
				}else {
					this.form.data.agent_code.rules = 'required';
				}
			}
		},
		methods: {
			async submit() {
				if (!this.validateForm(this.form)) {
					return false;
				}

				this.form.error = false;
				this.form.loading = true;

				await this.$post({
					url: `${this.$baseurl}/admins`,
					data: this.getFormData(),
					headers: this.headers,
					success: response => {
						this.company = response.data.data;
						this.$refs.successModal.open();
						this.form = this.resetForm(this.form);
					},
					error: error => {
						this.form.error = error;

						this.mapFormErrors(this.form, error?.response?.data?.errors);
					}
				});

				this.form.loading = false;
			}
		}
	}
</script>